import Swal from 'sweetalert2'

const show = (options = {}) => {
  const borderColors = {
    success: 'border-light-success',
    error: 'border-light-danger',
    warning: 'border-light-warning',
  }

  Swal.fire({
    title: options.title,
    text: options.message,
    icon: options.type,
    toast: true,
    timer: options.timer || 5000,
    position: options.position || 'top-end',
    showConfirmButton: options.showConfirmButton || false,
    showCloseButton: true,
    customClass: {
      popup: `rounded-8 bg-light shadow-none border border-2 ${
        borderColors[options.type]
      }`,
      title: 'font-base fw-normal me-5',
    },
  })
}

export const errorToast = (options = {}) => {
  options.type = 'error'
  options.title = options.title || I18n.t('something_went_wrong_notification')
  show(options)
}

export const warningToast = (options = {}) => {
  options.type = 'warning'
  show(options)
}

export const successToast = (options = {}) => {
  options.type = 'success'
  options.title = options.title || I18n.t('success')
  show(options)
}

export const errorCenterToast = (options = {}) => {
  Swal.fire({
    icon: 'error',
    title: options.title,
    text: options.message,
  })
}
