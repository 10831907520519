import { Formik, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { errorToast, successToast } from '../lib/toastAlert'

const validationSchema = Yup.object().shape({
  message: Yup.string().required('Required'),
  full_name: Yup.string().required('Required'),
})

function NameForm({ values, handleChange }) {
  return (
    <div>
      <label>{I18n.t('contact.your_name')}: </label>
      <input
        name="full_name"
        className="form-control"
        value={values.full_name}
        onChange={handleChange}
        disabled={App.State.fullName}
        required
      />
    </div>
  )
}

const message =
  App.State.contactableType === 'Car'
    ? I18n.t('contact.contact_message', {
        car_title: App.State.carTitle,
        car_url: App.State.carUrl,
      })
    : ''

const initialValues = {
  message: message.replace(/\\n/g, '\n'),
  full_name: App.State.fullName,
}

/* eslint-disable camelcase */
export default function ContactSellerForm({ modal, closeModal }) {
  const handleSubmit = (values, actions) => {
    actions.setStatus({ message: I18n.t('contact.sending'), type: 'warning' })
    actions.setSubmitting(true)

    values.messageable_type = App.State.contactableType
    values.messageable_id = App.State.contactableId

    App.Request.post('/account/messages', values)
      .then(({ data, status }) => {
        if (status === 201) {
          const subject_id = data.subject_id ? data.subject_id : data.id

          actions.resetForm()
          actions.setStatus({
            message: (
              <span>
                {I18n.t('contact.sent_successfully')}{' '}
                <a
                  className="text-success"
                  href={`/account/messages/${subject_id}`}
                >
                  [View conversation]
                </a>
              </span>
            ),
            type: 'success',
          })

          if (App.State.contactableType === 'Car') {
            App.GAEvent('Car Page', 'Submit Contact Form', null, null)
          }

          successToast({ title: I18n.t('contact.sent_successfully') })
        }
      })
      .catch((err) => {
        if (err.response.status === 422) {
          actions.setStatus({
            message: I18n.t('contact.not_sent'),
            type: 'danger',
          })
          actions.setErrors(err.response.data)

          errorToast({ title: I18n.t('contact.not_sent') })
        } else {
          actions.setStatus({
            message: I18n.t('contact.something_went_wrong_notification'),
            type: 'danger',
          })
          errorToast({
            title: I18n.t('contact.something_went_wrong_notification'),
          })
        }
      })
      .finally(() => {
        actions.setSubmitting(false)
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange={false}
      children={(props) => (
        <form onSubmit={props.handleSubmit} className="mb-3">
          <div className="mb-3">
            <NameForm {...props} />
          </div>
          <div className="mb-3">
            <label htmlFor="message-form">{I18n.t('message')}:</label>
            <textarea
              name="message"
              id="message-form"
              className="form-control contact-seller-text"
              cols="30"
              rows="10"
              readOnly={!App.State.userSignedIn}
              placeholder={I18n.t('message')}
              value={props.values.message}
              onChange={props.handleChange}
            />
            {App.State.reachedMessagesLimit && (
              <p className="text-danger">{I18n.t('contact.confirm_email')}</p>
            )}

            <ErrorMessage
              name="message"
              className="text-danger"
              component="div"
            />
          </div>

          {props.status && props.status.message && (
            <p className={`text-${props.status.type}`}>
              {props.status.message}
            </p>
          )}
          <div className="mt-2">
            <button
              type="submit"
              className="btn btn-primary rounded-8 me-2 px-7"
              disabled={props.isSubmitting || !App.State.userSignedIn || !App.State.isConfirmed}
            >
              {I18n.t('send')}
            </button>
            <a
              href="https://my.escrow.com/partner.asp?pid=584479"
              className="btn secondary rounded-8 me-2"
              style={{ background: '#E6ECF1' }}
              target="_blank"
              rel="noopener"
            >
              Buy Securely with
              <img src="https://assets.dyler.com/website/ads_banners/Escrow_com_logo%201.png" />
            </a>

            {modal && (
              <button
                className="btn btn-secondary rounded-8 float-right"
                type="button"
                onClick={() => closeModal()}
              >
                {I18n.t('close')}
              </button>
            )}
          </div>
          {props.isSubmitting ||
            !App.State.userSignedIn ||
            !App.State.isConfirmed &&
              <span className="text-danger">
                {I18n.t('contact.disable_send_text')}
              </span>
          }
        </form>
      )}
    />
  )
}
