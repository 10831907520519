import { render } from 'react-dom'
import ContactSellerForm from '../../components/ContactSellerForm'

document.addEventListener('DOMContentLoaded', () => {
  if (App.State.userSignedIn) {
    render(
      <ContactSellerForm />,
      document.getElementById('contact-seller-form')
    )
  }
})
